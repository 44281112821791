<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-6">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">{{ $t("Inventory") }}</li>
                <li class="breadcrumb-item">Stock Opname</li>
                <li class="breadcrumb-item">Add Form</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Table -->
      <div class="row">
        <div class="col">
          <div class="card mt-2">
            <div class="card-header">
              <!-- Title -->
              <ul class="nav nav-tabs nav-overflow header-tabs mb-2">
                <li class="nav-item" @click="direct = 'Medicine'">
                  <a class="nav-link" :class="{ active: direct === 'Medicine' }">
                    Medicine
                  </a>
                </li>
                <li class="nav-item" @click="direct = 'Disposable'">
                  <a
                    class="nav-link"
                    :class="{ active: direct === 'Disposable' }">
                    Disposable Item
                  </a>
                </li>
                <li class="nav-item" @click="direct = 'Medical Tool'">
                  <a
                    class="nav-link"
                    :class="{ active: direct === 'Medical Tool' }">
                    Medical Tool
                  </a>
                </li>
              </ul>

              <div>
                <b-button
                  class="no-wrap btn-pill float-end"
                  title="Detail"
                  variant="primary"
                  @click="submit()" v-if="isLoading == false">
                  <span class="fe fe-save"></span>
                  Submit
                </b-button>
                <button class="no-wrap btn-pill float-end" type="button" disabled
                        v-else>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
              </div>

              <!-- <h4 class="card-header-title">{{ $t('Detail') }}</h4> -->
              <!-- Button -->
            </div>
            <div class="d-flex justify-content-end mb-0 mr-3 mt-4">
              <div class="d-flex">
                <p class="mt-2">
                  Date <span class="text-danger mr-1"> *</span>
                </p>
                <input
                  type="date"
                  class="form-control mr-5"
                  style="width: 220px !important"
                  v-model="submits.opname_date"
                  :max="now()" />
              </div>
              <p class="mr-2 mt-2">Search</p>
              <b-form-input
                v-model="body.searchQuery"
                class="w-auto"
                placeholder="Type Here..."
                @change="getTable()"></b-form-input>
            </div>
            <!-- Disini table -->
            <div class="card-body p-0" v-if="direct === 'Medicine'">
              <Medicine />
            </div>
            <div class="card-body p-0" v-if="direct === 'Medical Tool'">
              <MedicalTool />
            </div>
            <div class="card-body p-0" v-if="direct === 'Disposable'">
              <Disposable />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
  </div>
</template>
<script>
import Medicine from "@/components/inventory/stock-opname/medicine.vue";
import Disposable from "@/components/inventory/stock-opname/disposable.vue";
import MedicalTool from "@/components/inventory/stock-opname/medical.vue";
import moment from "moment";
export default {
  name: "inventoryInputDO",
  components: {
    Medicine,
    Disposable,
    MedicalTool
  },
  computed: {
    dataDO() {
      return this.$store.getters["Do/DeliveryOrderData"]
        ? this.$store.getters["Do/DeliveryOrderData"]
        : [];
    },
    drug() {
      return this.$store.getters["drug/DrugData"]
        ? this.$store.getters["drug/DrugData"]
        : [];
    },
    tables() {
      return this.$store.getters["opname/tableRevData"]
        ? this.$store.getters["opname/tableRevData"]
        : [];
    }
    // dataSelected() {
    //     return this.$store.getters["opname/productSelectedData"] ?
    //         this.$store.getters["opname/productSelectedData"] : [];
    // },
  },
  data() {
    return {
      direct: "Medicine",
      body: {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: "",
        type: "MEDICINE"
      },
      bodyDo: {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: ""
      },
      dataDetail: {},
      dataTable: {},
      dataSend: {
        so_id: "",
        item_id: "",
        expiry_date: "",
        uom: [],
        note: ""
      },
      dataView: {
        item_label: "",
        sap_material_number: "",
        item_manufacturer: "",
        expiry_date: "",
        uom_label: "",
        uom: [],
        stock_system: "",
        note: ""
      },
      tempExp: [],
      tempUom: [],
      submits: {
        opname_date: moment().format("YYYY-MM-DD"),
        sap_do_number: "",
        note: ""
      },
      // now: moment().format("YYYY-MM-DD"),
      stock_system: "",
      datas: [
        {
          id: 1,
          date: "28 Feb 2023 ",
          diff: "Rp. 200.000"
        },
        {
          id: 2,
          date: "24 Feb 2023",
          diff: "Rp. 553.342"
        }
      ],
      listcolumns: [
        {
          label: "ID",
          field: "sap_material_number",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Product Name",
          field: "item_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Expired Date",
          field: "expiry_date",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Stock System",
          field: "stock_system",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Actual Stock",
          field: "actual_stock",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Difference",
          field: "item_diff_qty",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Remark",
          field: "note",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },

        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle text-center",
          sortable: false
        }
      ],
      isLoading: false
    };
  },
  mounted() {
    // this.getDataDO();
    // this.getTable();
    // this.getDrug();
  },
  methods: {
    convertDate(d) {
      return moment(d).format("D MMM YYYY");
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getTable();
    },

    convertCurrency(dat) {
      return dat.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    konst(evt, index) {
      if (evt.keyCode)
        switch (evt.keyCode) {
          case 13:
            evt.preventDefault();
            this.dataSend.uom[index].item_qty = evt.srcElement.innerText;

            // const value = document.getElementById(index + "a").innerText;
            // const body = {
            //     id,
            //     [vars]: value,
            // };
            // this.$store.dispatch("po/updateInner", body);
            break;
          default:
            if (
              (evt.keyCode >= 48 && evt.keyCode <= 57) ||
              (evt.keyCode >= 37 && evt.keyCode <= 40) ||
              evt.keyCode == 8 ||
              evt.keyCode == 46
            ) {
            } else {
              evt.preventDefault();
            }
            break;
        }
    },

    setValue(e, index) {
      if (e.srcElement.innerText) {
        if (parseInt(e.srcElement.innerText) !== NaN) {
          this.dataSend.uom[index].item_qty = parseInt(e.srcElement.innerText);
        }
      }
    },

    saveAdd() {
      // delete this.dataSend.item_manufacturer

      this.$store.dispatch("opname/addRevision", this.dataSend).then(() => {
        this.$bvModal.hide("modal-1");
        this.$swal("Success!", "This Record Has been Saved", "success");
        this.getTable();
      });
    },

    reset() {
      this.dataSend = {
        so_id: "",
        item_id: "",
        expiry_date: [],
        item_manufacturer: "",
        sap_material_number: "",
        uom: [],
        revision_type: "",
        note: ""
      };
      this.stock_system = "";
    },

    now() {
      return moment().format("YYYY-MM-DD");
    },

    // api hit
    getDrug(e) {
      let body = {
        id: e.item_id,
        expiry_date: e.expiry_date
      };
      this.$store.dispatch("opname/getSelected", body).then(() => {
        let data = this.$store.getters["opname/productSelectedData"];
        this.dataView.expiry_date = data.expiry_date;
        this.dataView.item_label = data.item_label;
        this.dataView.item_manufacturer = data.item_manufacturer;
        this.dataView.sap_material_number = data.sap_material_number;
        this.dataView.stock_system = data.stock_system;
        this.dataView.uom_label = data.uom_label;

        this.dataSend.item_id = data.item_id;
        this.dataSend.expiry_date = data.expiry_date;
        this.dataSend.uom = data.uom;
        this.dataSend.note = data.note;
        this.$bvModal.show("modal-1");

        // })
      });
    },
    getTable() {
      this.$store.dispatch("opname/getTableAddRevision", this.body);
    },

    async getDataDO() {
      this.isLoading = true;
      this.$store.dispatch("Do/getListDO", this.bodyDo).finally(() => {
        this.isLoading = false;
      });
    },

    // productUnit() {
    //     this.$store.dispatch("opname/getSelected", this.dataSend.item_id).then(() => {
    //         let data = this.$store.getters["opname/productSelectedData"];
    //         this.dataSend.item_manufacturer = data.item_manufacturer
    //         this.dataSend.sap_material_number = data.sap_material_number
    //         this.tempExp = data.expiry_date
    //         this.dataSend.uom = data.uom
    //         // this.bodyAdd.sap_material_number = data.sap_material_number;
    //     });
    // },

    inputExp() {
      this.$store
        .dispatch("opname/getSelectedExp", {
          id: this.dataSend.item_id,
          expiry_date: this.dataSend.expiry_date
        })
        .then(() => {
          let data = this.$store.getters["opname/selectedExpData"];

          this.stock_system = data.label;
        });
    },

    deleteRow(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, Reset it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("opname/deleteRev", id)
            .then((resp) => {
              this.getTable();
              this.$swal(
                "Deleted!",
                "This Record has been deleted!",
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    submit() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Submitted Stock Opname cannot be changed later!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("opname/SubmitRevs", this.submits)
              .then(() => {
                this.$router.push("/clinic-management/inventory/stock-opname");
              })
              .catch((resp) => {
                let err = resp.response.data;

                this.$swal.fire("Error!", `${err.error}`, "error");
              });
          }
        });
    },

    onPageChange(payload) {
      this.body.perPage = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getTable();
    }
  }
};
</script>

<template>
  <!-- HEADER -->
     <div>
            <div class="card-body">
              <vue-good-table
                mode="remote"
                theme="polar-bear"
                :line-numbers="true"
                :columns="listcolumns"
                :rows="tables.row"
                :total-rows="tables.totalRecords"
                :sort-options="{
                  enabled: true,
                  initialSortBy: { field: 'item_label', type: 'asc' }
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'pages',
                  position: 'bottom',
                  perPage: 10,
                  perPageDropdown: [10, 50, 100],
                  dropdownAllowAll: false,
                  setCurrentPage: 1,
                  nextLabel: 'next',
                  prevLabel: 'prev',
                  rowsPerPageLabel: 'Rows per page',
                  ofLabel: 'of',
                  pageLabel: 'Page',
                  allLabel: 'All'
                }"
                style-class="vgt-table table table-bordered"
                @on-page-change="onPageChange"
                @on-per-page-change="onPageChange"
                @on-sort-change="onSortChange"
                :isLoading.sync="isLoading">
                <!-- Loading -->
                <div slot="emptystate" class="text-center font-size-14">
                  No data available
                </div>
                <template slot="table-row" slot-scope="props">
                  <!-- Table Action -->
                  <span v-if="props.column.field == 'action'">
                    <b-button
                      class="no-wrap btn-pill"
                      title="Edit"
                      variant="warning"
                      size="sm"
                      @click="getDrug(props.row)">
                      <span class="fe fe-edit"></span>
                    </b-button>
                    <b-button
                      class="no-wrap btn-pill ml-2"
                      title="Reset"
                      variant="danger"
                      size="sm"
                      @click="deleteRow(props.row.cart_id)"
                      v-if="props.row.cart_id != null">
                      <span class="fe fe-rotate-ccw"></span>
                    </b-button>
                  </span>
                  <!-- <span v-else-if="props.column.field == 'price'">
                                        Rp. {{ convertNumber(props.row.price) }}
                                    </span> -->
                </template>
              </vue-good-table>
            </div>

    <!-- Modal -->
    <b-modal id="modal-1" hide-footer title="Adjustment Stock" size="lg">
      <form @submit.prevent="saveAdd()">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>{{ $t("Product") }}</label>
              <p>
                <b>{{ dataView.item_label }}</b>
              </p>
            </div>
            <div class="form-group">
              <label>{{ $t("Manufacture") }}</label>
              <p>
                <b>{{ dataView.item_manufacturer }}</b>
              </p>
              <!-- <input type="text" class="form-control" v-model="dataView.item_manufacturer" disabled> -->
            </div>
            <div class="form-group">
              <label>{{ $t("SAP Material Number") }}</label>
              <p>
                <b>{{ dataView.sap_material_number }}</b>
              </p>
              <!-- <input type="text" class="form-control" v-model="dataView.sap_material_number" disabled> -->
            </div>
            <div class="form-group">
              <label>{{ $t("Unit of Matric") }}</label>
              <p>
                <b>{{ dataView.uom_label }}</b>
              </p>
            </div>
            <div class="form-group">
              <label>{{ $t("Curent System Stock") }}</label>
              <p>
                <b>{{ dataView.stock_system }}</b>
              </p>
            </div>
            <div class="form-group">
              <label>{{ $t("Expired Date") }}</label>
              <p>
                <b>{{ convertDate(dataView.expiry_date) }}</b>
              </p>
            </div>
          </div>
          <div class="col">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Unit</th>
                  <th scope="col">Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, index) in dataSend.uom" :key="`x${index}`">
                  <td>{{ e.item_uom_name }}</td>
                  <td
                    contenteditable
                    @input="setValue($event, index)"
                    @keydown="konst($event, index)">
                    {{ e.item_qty }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="form-group mt-2">
              <label>{{ $t("Note") }}</label>
              <textarea class="form-control" v-model="dataSend.note" />
            </div>
          </div>
        </div>

        <div class="form-group float-end">
          <!-- <button style="" class="mt-4 btn btn-secondary" @click="reset">
            {{ $t("Reset") }}
          </button> -->
          <button style="" class="ml-2 mt-4 btn btn-primary" type="submit" v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled
                        v-else>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
        </div>
      </form>
    </b-modal>
    </div>
</template>
<script>
import moment from "moment";
export default {
  name: "inventoryInputDO",
  computed: {
    dataDO() {
      return this.$store.getters["Do/DeliveryOrderData"]
        ? this.$store.getters["Do/DeliveryOrderData"]
        : [];
    },
    drug() {
      return this.$store.getters["drug/DrugData"]
        ? this.$store.getters["drug/DrugData"]
        : [];
    },
    tables() {
      return this.$store.getters["opname/tableRevData"]
        ? this.$store.getters["opname/tableRevData"]
        : [];
    }
    // dataSelected() {
    //     return this.$store.getters["opname/productSelectedData"] ?
    //         this.$store.getters["opname/productSelectedData"] : [];
    // },
  },
  data() {
    return {
      body: {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: "",
        type:"MEDICAL-EQUIPMENT"
      },
      bodyDo: {
        page: 1,
        perPage: 10,
        searchQuery: "",
        sort: ""
      },
      dataDetail: {},
      dataTable: {},
      dataSend: {
        so_id: "",
        item_id: "",
        expiry_date: "",
        uom: [],
        note: ""
      },
      dataView: {
        item_label: "",
        sap_material_number: "",
        item_manufacturer: "",
        expiry_date: "",
        uom_label: "",
        uom: [],
        stock_system: "",
        note: ""
      },
      tempExp: [],
      tempUom: [],
      submits: {
        opname_date: moment().format("YYYY-MM-DD"),
        sap_do_number: "",
        note: ""
      },
      // now: moment().format("YYYY-MM-DD"),
      stock_system: "",
      datas: [
        {
          id: 1,
          date: "28 Feb 2023 ",
          diff: "Rp. 200.000"
        },
        {
          id: 2,
          date: "24 Feb 2023",
          diff: "Rp. 553.342"
        }
      ],
      listcolumns: [
        {
          label: "ID",
          field: "sap_material_number",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Product Name",
          field: "item_label",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Expired Date",
          field: "expiry_date",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Stock System",
          field: "stock_system",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Actual Stock",
          field: "actual_stock",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Difference",
          field: "item_diff_qty",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Remark",
          field: "note",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },

        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle text-center",
          sortable: false
        }
      ],
      isLoading: false
    };
  },
  mounted() {
    this.getDataDO();
    this.getTable();
    // this.getDrug();
  },
  methods: {
    convertDate(d) {
      return moment(d).format("D MMM YYYY");
    },

    onSortChange(params) {
      let sort1 = params[0].field;
      let sort2 = params[0].type;
      this.body.sort = `${sort1},${sort2}`;
      this.getTable();
    },

    convertCurrency(dat) {
      return dat.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    konst(evt, index) {
      if (evt.keyCode)
        switch (evt.keyCode) {
          case 13:
            evt.preventDefault();
            this.dataSend.uom[index].item_qty = evt.srcElement.innerText;

            // const value = document.getElementById(index + "a").innerText;
            // const body = {
            //     id,
            //     [vars]: value,
            // };
            // this.$store.dispatch("po/updateInner", body);
            break;
          default:
            if (
              (evt.keyCode >= 48 && evt.keyCode <= 57) ||
              (evt.keyCode >= 37 && evt.keyCode <= 40) ||
              evt.keyCode == 8 ||
              evt.keyCode == 46
            ) {
            } else {
              evt.preventDefault();
            }
            break;
        }
    },

    setValue(e, index) {
      if (e.srcElement.innerText) {
        if (parseInt(e.srcElement.innerText) !== NaN) {
          this.dataSend.uom[index].item_qty = parseInt(e.srcElement.innerText);
        }
      }
    },

    saveAdd() {
      this.isLoading = true
      this.$store.dispatch("opname/addRevision", this.dataSend).then(() => {
        this.isLoading = false
        this.$bvModal.hide("modal-1");
        this.$swal("Success!", "This Record Has been Saved", "success");
        this.getTable();
      }).catch((err) => {
        this.isLoading = false
        this.$swal.fire("Error!", `${err.response.data.error}`, "error");
      });
    },

    reset() {
      this.dataSend = {
        so_id: "",
        item_id: "",
        expiry_date: [],
        item_manufacturer: "",
        sap_material_number: "",
        uom: [],
        revision_type: "",
        note: ""
      };
      this.stock_system = "";
    },

    now() {
      return moment().format("YYYY-MM-DD");
    },

    // api hit
    getDrug(e) {
      let body = {
        id: e.item_id,
        expiry_date: e.expiry_date
      };
      this.$store.dispatch("opname/getSelected", body).then(() => {
        let data = this.$store.getters["opname/productSelectedData"];
        this.dataView.expiry_date = data.expiry_date;
        this.dataView.item_label = data.item_label;
        this.dataView.item_manufacturer = data.item_manufacturer;
        this.dataView.sap_material_number = data.sap_material_number;
        this.dataView.stock_system = data.stock_system;
        this.dataView.uom_label = data.uom_label;

        this.dataSend.item_id = data.item_id;
        this.dataSend.expiry_date = data.expiry_date;
        this.dataSend.uom = data.uom;
        this.dataSend.note = data.note;
        this.$bvModal.show("modal-1");

        // })
      });
    },
    getTable() {
      this.$store.dispatch("opname/getTableAddRevision", this.body);
    },

    async getDataDO() {
      this.isLoading = true;
      this.$store.dispatch("Do/getListDO", this.bodyDo).finally(() => {
        this.isLoading = false;
      });
    },

    // productUnit() {
    //     this.$store.dispatch("opname/getSelected", this.dataSend.item_id).then(() => {
    //         let data = this.$store.getters["opname/productSelectedData"];
    //         this.dataSend.item_manufacturer = data.item_manufacturer
    //         this.dataSend.sap_material_number = data.sap_material_number
    //         this.tempExp = data.expiry_date
    //         this.dataSend.uom = data.uom
    //         // this.bodyAdd.sap_material_number = data.sap_material_number;
    //     });
    // },

    inputExp() {
      this.$store
        .dispatch("opname/getSelectedExp", {
          id: this.dataSend.item_id,
          expiry_date: this.dataSend.expiry_date
        })
        .then(() => {
          let data = this.$store.getters["opname/selectedExpData"];

          this.stock_system = data.label;
        });
    },

    deleteRow(id) {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("You won't be able to revert this!"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: this.$t("Yes, Reset it!"),
        cancelButtonText: this.$t("Cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("opname/deleteRev", id)
            .then((resp) => {
              this.getTable();
              this.$swal(
                "Deleted!",
                "This Record has been deleted!",
                "success"
              );
            })
            .catch((resp) => {
              this.$swal("Error", this.$t("Error while delete data"), "error");
            });
        }
      });
    },

    submit() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Submitted Stock Opname cannot be changed later!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("opname/SubmitRevs", this.submits)
              .then(() => {
                this.$router.push("/clinic-management/inventory/stock-opname");
              })
              .catch((resp) => {
                let err = resp.response.data;

                this.$swal.fire("Error!", `${err.error}`, "error");
              });
          }
        });
    },

    onPageChange(payload) {
      this.body.perPage = payload.currentPerPage;
      this.body.page = payload.currentPage;
      this.getTable();
    }
  }
};
</script>
